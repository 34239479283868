//color
$usp-white-color: rgb(255, 255, 255);
$usp-black-color: rgb(0, 0, 0);
$usp-dark-grey-color: rgb(38, 38, 38);
$usp-dark-grey-color-2: rgb(60, 60, 60);
$usp-blue-color: rgb(0, 143, 211);
$usp-dark-blue-color: rgba(0, 125, 184);
$usp-grey-color: rgb(114, 114, 114);
$usp-light-grey-color: rgb(112, 112, 112);
$usp-light-grey-color-2: rgb(173, 173, 173);
$usp-light-grey-color-3: rgb(234, 234, 234);
$usp-gold-color: rgb(245, 166, 35);
$usp-red-dark1-color: rgba(164, 54, 14);

$usp-main-box-shadow: 0px 0px 10px 1px rgba(166, 166, 166, 0.35);

//font
$usp-font-regular: BentonSans-Regular, sans-serif;
$usp-font-bold: BentonSans-Bold, sans-serif;
$usp-font-book: BentonSans-Book, sans-serif;
$usp-font-medium: BentonSans-Medium, sans-serif;

//font size
$usp-font-size-xxl: 38px;
$usp-font-size-xl: 28px;
$usp-font-size-l: 20px;
$usp-font-size-m: 16px;
$usp-font-size-s: 12px;

//padding
$usp-padding-xl: 90px;
$usp-padding-m: 51px;
$usp-padding-s: 28px;
$usp-padding-sm: 16px;

$usp-indent-xxl: 84px;
$usp-indent-xl: 52px;
$usp-indent-l: 32px;
$usp-indent-m: 20px;
$usp-indent-s: 12px;

//width and margin (custom)
$usp-custom-width-xl: 1036px;
$usp-custom-width-l: 862px;

$usp-custom-padding-xl: 174px;
$usp-custom-padding-xs: 8px;

$usp-custom-height-xl: 63px;
$usp-custom-height-l: 53px;


//screen breakpoint
$usp-breakpoint-xl: 1199.98px;
$usp-breakpoint-l: 979.98px;
$usp-breakpoint-m: 767.98px;
$usp-breakpoint-sm: 575.98px;
