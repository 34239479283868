$assets-path: '~ng-design-system/assets/';
@import '~ng-design-system/assets/styles/design-system';
@import '~ng-design-system/assets/styles/custom';
@import '~normalize.css';
@import './variables.scss';

@font-face {
  font-family: "SAP-icons";
  src: url("~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/SAP-icons.woff")
  format("woff");
  font-weight: normal;
  font-style: normal;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.usp-main-font-bold {
  font-family: $usp-font-bold;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

// to avoid header overlap issues
newlxp-announcements {
  min-height: auto!important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

:host {
  display: flex;
  flex: 1;
}

.usp-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  .usp-main-content-wrapper {
    padding: 0 $usp-padding-xl;
    flex-grow: 1;

    @media only screen and (max-width: $usp-breakpoint-xl) {
      padding: 0 $usp-padding-m;
    }
    @media only screen and (max-width: $usp-breakpoint-m) {
      padding: 0 $usp-padding-s;
    }
  }
}

.background-gradient {
  background: transparent linear-gradient(223deg, rgba(255, 255, 255, 1) 0%, rgba(234, 234, 234, 1) 100%) 0% 0% no-repeat padding-box;
}

.btn.btn-outline-primary:focus,
.btn.ds-button--secondary:focus,
.btn-outline-primary.ds-button:focus,
.ds-button.ds-button--secondary:focus,
.btn.btn-primary:focus, .btn.ds-button--primary:focus,
.btn-primary.ds-button:focus,
.ds-button.ds-button--primary:focus {
  outline: none;
}

.custom-dialog-container {
  font-family: $usp-font-regular;
  width: 540px;
  height: 234px;
  border-radius: 0;
  background-color: $usp-white-color;
  @media only screen and (max-width: $usp-breakpoint-sm) {
    width: 360px;
    height: fit-content;
  }

  .mat-dialog-container {
   padding: 0;
    overflow: hidden;
  }
}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.85);
}

html, body { height: 100%; }
body { margin: 0; font-family: $usp-font-regular; }

.ds-message--success {
  .ds-icon--close::before {
    content: url("assets/icons/close-green.svg");
  }
  .ds-icon--checked-circle::before {
    content: url("assets/icons/success.svg");
  }
}
.ds-message--error {
  .ds-icon--close::before {
    content: url("assets/icons/close-red.svg");
  }
  .ds-icon--close-outline::before {
    content: url("assets/icons/error.svg");
  }
}
.ds-icon--arrow-down::before {
  content: url("assets/icons/arrowdown.svg");
}

app-usp-root {
  flex: 1;
}

ui5-static-area ui5-static-area-item {
  --udex-tab-overflow-button-line-height: 2;
  --udex-tab-overflow-button-font-family: Roboto, "Helvetica Neue", sans-serif;
  --udex-tab-overflow-button-font-size: 16px;
  --udexSpacer8: 8px;
  --udexSpacer12: 12px;
}
